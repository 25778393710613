export const appConstants = {
    baseUrl: "https://teapoy.dci.in/api/v1/admin/",
    // baseUrl: "https://backend.teapoy.dci.in/api/v1/admin/",
    baseVideoUrl: "https://newteapoy.s3.amazonaws.com/video/",
    baseImgUrl: "https://newteapoy.s3.amazonaws.com/coverimage/",
    login: "Admin Login",
    emailAddress: "Email Address",
    logoutWarning: "Are you sure, you want to logout?",
    logout: "Log out",
    yes: "Yes",
    no: "No",
    email: "Email",
    dashboard: "Dashboard",
    totalCustomers: "Total Customers",
    totalServiceProvider: "Total Service Provider",
    stats: "Stats",
    activeAccounts: "Active accounts",
    top5Categories: "Top 5 Categories",
    customer: "Customer",
    serviceProvider: "Service Provider",
    active: "Active",
    signout: "Signout",
    totalActiveAccounts: "Total active accounts",
    viewAllCategory: "View all category",
    forgotPasswordText: "Remember & input your Email address below",
    continue: "Continue",
    authCopyRightText: "Buzz Plantforms INC. All Rights Reserved.",
    forgotPassword: "Forgot Password",
    otpVerification: "OTP Verification",
    otpVerificationText: "We just sent you a verification code to your email",
    verify: "Verify",
    resendOtpText: "Resend Code in 00:",
    createNewPassword: "Create new password",
    createPasswordText: "Enter your new password here",
    reset: "Reset",
    confirmNewPassword: "Confirm new password",
    customers: "Customers",
    exportCsv: "Export CSV",
    importCsv: "Import CSV",
    name: "Name",
    phoneNumber: "Phone Number",
    emailId: "E-mail ID",
    emailid: "Email ID",
    status: "Status",
    actions: "Actions",
    action: "Action",
    previous: "Previous",
    next: "Next",
    serviceProviders: "Service providers",
    add: "Add",
    serviceCategory: "Service category",
    subCategory: "Sub Category",
    subcategory: "Sub category",
    manage: "Manage",
    serviceCategories: "Service categories",
    trendingServices: "Trending services",
    serviceName: "Service name",
    delete: "Delete",
}